import * as tslib_1 from "tslib";
import Assets from '@/classes/Assets';
import Router from '@/classes/Router';
import Sentinel from '@/classes/Sentinel';
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { FB } from '@/db/FB';
let ConfigurationPage = class ConfigurationPage extends Vue {
    constructor() {
        super(...arguments);
        this.Assets = Assets;
        this.user = Sentinel.getUser(true, '/login');
        this.error = '';
    }
    created() {
        Sentinel.watchUser(this, (data) => {
            this.user = data;
        });
    }
    setType(type) {
        this.user.userType = type;
    }
    doSave() {
        this.user.lastUpdate = moment().format('MM/DD/YYYY HH:mm:ss');
        FB.database.ref('/users/' + this.user.uid).update(this.user).then((data) => {
            Sentinel.setUser(this.user);
            Router.goTo('/settings');
        }).catch((error) => {
            this.error = error.message;
        });
    }
    clearError() {
        this.error = '';
    }
    goBack() {
        Router.goTo('/settings');
    }
    fileInput() {
        return this.$refs.fileInput;
    }
    onInputChanged() {
        if (this.fileInput().files && this.fileInput().files[0]) {
            var image = this.fileInput().files[0];
            if (image.type !== 'image/jpeg' && image.type !== 'image/png') {
                this.error = 'Las imágen debe ser de tipo JPG o PNG';
                return;
            }
            if (image.size > 3000000) {
                this.error = 'La imagen no puede ser mayor de 3 MB';
                return;
            }
            var reader = new FileReader();
            var me = this;
            reader.onload = function (ev) {
                me.user.logo = ev.target.result.toString();
            };
            reader.readAsDataURL(this.fileInput().files[0]);
        }
    }
};
ConfigurationPage = tslib_1.__decorate([
    Component
], ConfigurationPage);
export default ConfigurationPage;
