import { Subject } from "rxjs";
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
export class FB {
    static init() {
        firebase.initializeApp(this.firebaseConfig);
        FB.auth = firebase.auth();
        FB.database = firebase.database();
        this.database.ref('/').once('value').then((snapshot) => {
            this.adhesives = snapshot.val().adhesives;
            this.joints = snapshot.val().joints;
            this.supports = snapshot.val().supports;
            this.tiles = snapshot.val().tiles;
            this.tileTools = snapshot.val().tileTools;
            this.dataSubject.next(true);
        });
    }
    static watchData(subscriber, observer) {
        const subscription = this.dataSubject.asObservable().subscribe(observer);
    }
}
FB.firebaseConfig = {
    apiKey: 'AIzaSyDwnUqG1ZHJY3PlX3UWuli-ySxf3ozgDr8',
    authDomain: 'mapei-une138002.firebaseapp.com',
    databaseURL: 'https://mapei-une138002.firebaseio.com',
    projectId: 'mapei-une138002',
    storageBucket: 'mapei-une138002.appspot.com',
    messagingSenderId: '615391207818'
};
FB.dataSubject = new Subject();
FB.adhesives = [];
FB.joints = [];
FB.supports = [];
FB.tiles = [];
FB.tileTools = [];
FB.init();
