import * as tslib_1 from "tslib";
import Assets from '@/classes/Assets';
import Router from '@/classes/Router';
import Sentinel from '@/classes/Sentinel';
import { Component, Vue } from 'vue-property-decorator';
let HomePage = class HomePage extends Vue {
    constructor() {
        super(...arguments);
        this.Assets = Assets;
        this.showTerms = false;
        this.user = Sentinel.getUser();
    }
    startRecomendation() {
        if (this.user.isLogged()) {
            Router.goTo('/settings');
        }
        else {
            Router.goTo('/login');
        }
    }
};
HomePage = tslib_1.__decorate([
    Component
], HomePage);
export default HomePage;
