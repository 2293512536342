import * as tslib_1 from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import Router from '@/classes/Router';
let MenuItem = class MenuItem extends Vue {
    onClicked() {
        Router.goTo(this.to);
    }
};
tslib_1.__decorate([
    Prop({ default: '' })
], MenuItem.prototype, "to", void 0);
MenuItem = tslib_1.__decorate([
    Component
], MenuItem);
export default MenuItem;
