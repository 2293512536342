import * as tslib_1 from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
import Topbar from "@/components/Topbar/Topbar.vue";
import Footer from '@/components/Footer/Footer.vue';
import Router from './classes/Router';
let App = class App extends Vue {
    onCurrentRoutePathChanged(newValue) {
        Router.update();
    }
    created() {
        Router.init();
    }
};
tslib_1.__decorate([
    Watch('$route.path')
], App.prototype, "onCurrentRoutePathChanged", null);
App = tslib_1.__decorate([
    Component({
        components: {
            Topbar,
            Footer
        }
    })
], App);
export default App;
