import router from '@/router';
import Base64 from './Base64';
export default class Router {
    static init() {
        this.path = router.currentRoute.path;
    }
    static watchPath(callback) {
        Router.pathWatches.push(callback);
    }
    static watchParams(callback) {
        Router.paramsWatches.push(callback);
    }
    static update() {
        Router.path = router.currentRoute.path;
        Router.pathWatches.forEach(callback => callback(Router.path));
        Router.paramsWatches.forEach(callback => callback(Router.params));
    }
    static goTo(path, object, encode = false) {
        console.log(path);
        console.log(router.currentRoute);
        if ((router.currentRoute.path != path || object) &&
            !Router.excludedPaths.includes(router.currentRoute.path)) {
            let queryString = '';
            if (object) {
                if (router.currentRoute.path == path) {
                    object.hash = Math.floor((Math.random() * 1000000)) + 1;
                }
                queryString = Object.keys(object).map(key => key + '=' + object[key]).join('&');
                queryString = '?' + (encode ? 'q=' + Base64.encode(queryString) : queryString);
            }
            router.push(path + queryString, () => window.scrollTo(0, 0));
        }
    }
    static getParam(param) {
        return router.currentRoute.params[param];
    }
    static getQuery(decode = false) {
        let query = router.currentRoute.query;
        if (decode && query.q) {
            let decoded = Base64.decode(query.q);
            return this.parseQuery(decoded);
        }
        return query;
    }
    static loadJS(url, id, callback) {
        if (document.getElementById(id)) {
            return;
        }
        let script = document.createElement('script');
        script.setAttribute('src', url);
        script.setAttribute('id', id);
        if (callback) {
            script.addEventListener('load', () => { callback(); });
        }
        document.head.appendChild(script);
    }
    static goBack() {
        router.back();
    }
    static parseQuery(query) {
        var res = {};
        query = query.trim().replace(/^(\?|#|&)/, '');
        if (!query) {
            return res;
        }
        query.split('&').forEach(function (param) {
            var parts = param.replace(/\+/g, ' ').split('=');
            var key = decodeURIComponent(parts.shift().toString());
            var val = parts.length > 0
                ? decodeURIComponent(parts.join('='))
                : null;
            if (res[key] === undefined) {
                res[key] = val;
            }
            else if (Array.isArray(res[key])) {
                res[key].push(val);
            }
            else {
                res[key] = [res[key], val];
            }
        });
        return res;
    }
}
Router.excludedPaths = new Array();
Router.path = '';
Router.pathWatches = new Array();
Router.params = new Array();
Router.paramsWatches = new Array();
