import { render, staticRenderFns } from "./ConfigurationPage.html?vue&type=template&id=d829ce2e&scoped=true&"
import script from "./ConfigurationPage.ts?vue&type=script&lang=ts&"
export * from "./ConfigurationPage.ts?vue&type=script&lang=ts&"
import style0 from "./ConfigurationPage.scss?vue&type=style&index=0&id=d829ce2e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d829ce2e",
  null
  
)

export default component.exports