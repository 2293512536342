import { FB } from '@/db/FB';
export class User {
    constructor(data) {
        this.uid = '';
        this.userType = 0;
        this.name = '';
        this.email = '';
        this.company = '';
        this.address = '';
        this.phone = '';
        this.terms = '';
        this.logo = '';
        this.creationDate = '';
        this.lastUpdate = '';
        if (data) {
            Object.assign(this, data);
        }
    }
    ;
    isLogged() {
        return this.uid != '';
    }
    save(onSuccess, onError) {
        FB.database.ref('/users/' + this.uid).update(this).then((data) => {
            if (onSuccess) {
                onSuccess(data);
            }
        }).catch((error) => {
            if (onError) {
                onError(error);
            }
        });
    }
}
