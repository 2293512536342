import Vue from 'vue';
import App from './App.vue';
import { FB } from './db/FB';
import router from './router';
Vue.config.productionTip = false;
let app;
FB.auth.onAuthStateChanged(() => {
    if (!app) {
        app = new Vue({
            router,
            render: h => h(App)
        }).$mount('#app');
    }
});
