import Router from '@/classes/Router';
import { Subject } from "rxjs";
export class Phase {
    static next() {
        if (!Phase.canNext)
            return;
        Phase.actual++;
        if (Phase.actual > 2) {
            Phase.actual = 2;
        }
        this.dataSubject.next(Phase.actual);
    }
    static previous() {
        if (!Phase.canPrevious)
            return;
        Phase.actual--;
        if (Phase.actual < 0) {
            Phase.actual = 0;
        }
        this.dataSubject.next(Phase.actual);
        if (Phase.actual == 0) {
            Router.goBack();
        }
    }
    static setActual(value) {
        if (value >= 0 && value <= 2) {
            Phase.actual = value;
            this.dataSubject.next(Phase.actual);
        }
    }
    static setPreviousable(value) {
        Phase.canPrevious = value;
        this.changeSubject.next();
    }
    static setNextable(value) {
        Phase.canNext = value;
        this.changeSubject.next();
    }
    static watchPhase(subscriber, observer) {
        const subscription = this.dataSubject.asObservable().subscribe(observer);
    }
    static watchChange(subscriber, observer) {
        const subscription = this.changeSubject.asObservable().subscribe(observer);
    }
}
Phase.dataSubject = new Subject();
Phase.changeSubject = new Subject();
Phase.actual = 0;
Phase.canNext = false;
Phase.canPrevious = false;
