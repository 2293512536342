import * as tslib_1 from "tslib";
import Assets from '@/classes/Assets';
import Router from '@/classes/Router';
import Sentinel from '@/classes/Sentinel';
import { FB } from '@/db/FB';
import { Component, Vue } from 'vue-property-decorator';
let SettingsPage = class SettingsPage extends Vue {
    constructor() {
        super(...arguments);
        this.Assets = Assets;
        this.user = Sentinel.getUser(true, '/login');
        this.adhesives = FB.adhesives.filter((adhesive) => adhesive.name !== 'Consultar a MAPEI');
        this.joints = FB.joints;
        this.firstLogin = Sentinel.firstLogin;
    }
    created() {
        Sentinel.watchUser(this, (data) => {
            this.user = data;
        });
        FB.watchData(this, (data) => {
            this.adhesives = FB.adhesives;
            this.joints = FB.joints;
        });
    }
    hasAdhesive(adhesiveName) {
        return this.user.adhesiveList &&
            this.user.adhesiveList.includes(adhesiveName);
    }
    hasJoint(jointName) {
        return this.user.jointList &&
            this.user.jointList.includes(jointName);
    }
    onAdhesiveClicked(event, adhesiveName) {
        if (event.target.checked) {
            if (!this.user.adhesiveList) {
                this.user.adhesiveList = new Array();
            }
            this.user.adhesiveList.push(adhesiveName);
        }
        else {
            this.user.adhesiveList = this.user.adhesiveList.filter(adhesive => adhesive != adhesiveName);
        }
        this.user.save(() => {
            Sentinel.setUser(this.user);
        }, (error) => {
            console.log(error);
        });
    }
    onJointClicked(event, jointName) {
        if (event.target.checked) {
            if (!this.user.jointList) {
                this.user.jointList = new Array();
            }
            this.user.jointList.push(jointName);
        }
        else {
            this.user.jointList = this.user.jointList.filter(joint => joint != jointName);
        }
        this.user.save(() => {
            Sentinel.setUser(this.user);
        }, (error) => {
            console.log(error);
        });
    }
    doLogout() {
        Sentinel.logout();
    }
    goToConfiguration() {
        Router.goTo('/configuration');
    }
    goBack() {
        Router.goTo('/');
    }
    loginAccess() {
        Router.goTo('/recomendation');
    }
};
SettingsPage = tslib_1.__decorate([
    Component
], SettingsPage);
export default SettingsPage;
