import * as tslib_1 from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import VLink from '@/components/VLink/VLink.vue';
import Router from '@/classes/Router';
import { Phase } from '@/models/Phase';
let Footer = class Footer extends Vue {
    constructor() {
        super(...arguments);
        this.Router = Router;
        this.Phase = Phase;
        this.phase = Phase.actual;
        this.canPrevious = Phase.canPrevious;
        this.canNext = Phase.canNext;
    }
    created() {
        Phase.watchPhase(this, (phase) => {
            this.phase = phase;
        });
        Phase.watchChange(this, () => {
            this.canPrevious = Phase.canPrevious;
            this.canNext = Phase.canNext;
        });
    }
};
Footer = tslib_1.__decorate([
    Component({
        components: {
            VLink
        }
    })
], Footer);
export default Footer;
