import { User } from '@/models/User';
import { Subject } from "rxjs";
import Vue from 'vue';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
import Router from './Router';
import { FB } from '@/db/FB';
export default class Sentinel {
    static setUser(user, firstLogin = false) {
        this.user = user instanceof User ? user : new User(user);
        this.firstLogin = firstLogin;
        var cookiesUser = Vue.$cookies.get('user');
        var auxUser = {};
        Object.assign(auxUser, this.user);
        delete (auxUser.logo);
        delete (auxUser.terms);
        Vue.$cookies.set('user', Object.assign({}, auxUser));
        this.userSubject.next(this.user);
    }
    static logout() {
        Sentinel.removeUser('/');
    }
    static getUser(checkUser = true, redirect) {
        if (checkUser) {
            Sentinel.checkUser(redirect);
        }
        return this.user;
    }
    static watchUser(subscriber, observer) {
        const subscription = this.userSubject.asObservable().subscribe(observer);
        if (subscriber && subscriber instanceof Vue) {
            subscriber.$once('hook:beforeDestroy', () => {
                subscription.unsubscribe();
            });
        }
    }
    static removeUser(redirect) {
        Vue.$cookies.remove('user');
        this.user = new User();
        this.userSubject.next(this.user);
        if (redirect) {
            Router.goTo(redirect);
        }
    }
    static checkUser(redirectError, redirectOK) {
        if (Vue.$cookies.isKey('user') && Vue.$cookies.get('user').uid) {
            this.user = new User(Vue.$cookies.get('user'));
            FB.database.ref('/users/' + this.user.uid).once('value').then((snapshot) => {
                let user = snapshot.val();
                if (user) {
                    Sentinel.setUser(snapshot.val());
                    if (redirectOK) {
                        Router.goTo(redirectOK);
                    }
                }
                else {
                    Sentinel.removeUser(redirectError);
                }
            }).catch((error) => {
                Sentinel.removeUser(redirectError);
            });
        }
        else {
            Sentinel.removeUser(redirectError);
        }
    }
}
Sentinel.user = new User();
Sentinel.userSubject = new Subject();
Sentinel.publicPages = new Array();
Sentinel.privatePages = new Array();
