import * as tslib_1 from "tslib";
import Assets from '@/classes/Assets';
import { Component, Vue } from 'vue-property-decorator';
let TopBar = class TopBar extends Vue {
    constructor() {
        super(...arguments);
        this.Assets = Assets;
    }
    created() {
    }
};
TopBar = tslib_1.__decorate([
    Component
], TopBar);
export default TopBar;
