import * as tslib_1 from "tslib";
import Assets from '@/classes/Assets';
import Sentinel from '@/classes/Sentinel';
import { User } from '@/models/User';
import { Component, Vue } from 'vue-property-decorator';
import Router from '@/classes/Router';
import moment from 'moment';
import Validator from '@/classes/Validator';
import { FB } from '@/db/FB';
require('jquery');
let LoginPage = class LoginPage extends Vue {
    constructor() {
        super(...arguments);
        this.Assets = Assets;
        this.user = Sentinel.getUser();
        this.loginEmail = '';
        this.loginPassword = '';
        this.loginError = '';
        this.signupEmail = '';
        this.signupPassword = '';
        this.signupName = '';
        this.signupCompany = '';
        this.signupAddress = '';
        this.signupPhone = '';
        this.signupImage = '';
        this.signupTerms = '';
        this.signupType = 0;
        this.signupError = '';
        this.recoverEmail = '';
        this.recoverError = '';
        this.showLogin = false;
        this.showSignUp = false;
    }
    created() {
        if (this.user.isLogged()) {
            Router.goTo('/settings');
        }
    }
    setLogin() {
        this.showSignUp = false;
        this.showLogin = true;
        this.clear();
    }
    setSignUp() {
        this.showLogin = false;
        this.showSignUp = true;
        this.clear();
    }
    setSignupType(type) {
        this.signupType = type;
    }
    doLogin() {
        if (this.loginEmail.trim() == '' || this.loginPassword.trim() == '') {
            this.loginError = 'Introduzca su correo y contraseña';
            return;
        }
        if (!Validator.isValidEmail(this.loginEmail)) {
            this.loginError = 'Introduzca un email válido';
            return;
        }
        FB.auth.signInWithEmailAndPassword(this.loginEmail, this.loginPassword).then((userCredential) => {
            if (userCredential.user) {
                FB.database.ref('/users/' + userCredential.user.uid).once('value').then((snapshot) => {
                    let user = snapshot.val();
                    if (user) {
                        Sentinel.setUser(snapshot.val());
                        Router.goTo('/settings');
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
            else {
                this.loginError = 'Correo y/o contraseña no válidos';
            }
        }).catch((error) => {
            this.loginError = 'Correo y/o contraseña no válidos';
        });
    }
    doSignUp() {
        if (this.signupEmail.trim() == '' || this.signupPassword.trim() == '') {
            this.signupError = 'Introduzca su correo y contraseña';
            return;
        }
        if (!Validator.isValidEmail(this.signupEmail)) {
            this.signupError = 'Introduzca un email válido';
            return;
        }
        if (this.signupPassword.trim().length < 6) {
            this.signupError = 'La contraseña debe tener al menos 6 caracteres';
            return;
        }
        FB.auth.createUserWithEmailAndPassword(this.signupEmail, this.signupPassword).then((userCredential) => {
            //Upload logo to S3
            //Save user in database
            if (userCredential.user) {
                let user = new User({
                    uid: userCredential.user.uid,
                    name: this.signupName,
                    email: this.signupEmail,
                    company: this.signupCompany,
                    address: this.signupAddress,
                    phone: this.signupPhone,
                    terms: this.signupTerms,
                    logo: this.signupImage,
                    userType: this.signupType,
                    creationDate: moment().format('MM/DD/YYYY HH:mm:ss'),
                    lastUpdate: moment().format('MM/DD/YYYY HH:mm:ss')
                });
                user.save((data) => {
                    Sentinel.setUser(user, true);
                    Router.goTo('/settings');
                }, (error) => {
                    console.log(error);
                });
                // FB.database!.ref( '/users/' + user.uid ).update( user ).then(
                //     ( data : any ) => {
                //         Sentinel.setUser( user );
                //         Router.goTo( '/settings' );                            
                //     }
                // ).catch( 
                //     ( error : any ) => {
                //         console.log( error );
                //     }
                // )
            }
        }).catch((error) => {
            if (error.code == 'auth/email-already-in-use') {
                this.signupError = 'El email introducido ya está registrado en el sistema';
            }
        });
    }
    doRecover() {
        if (Validator.isValidEmail(this.recoverEmail)) {
            FB.auth.sendPasswordResetEmail(this.recoverEmail).then(() => {
                this.closeModal();
            }).catch((error) => {
                if (error.code == 'auth/user-not-found') {
                    this.recoverError = 'El email introducido no está registrado en el sistema';
                }
            });
        }
        else {
            this.recoverError = 'Introduzca un email válido';
        }
    }
    closeModal() {
        this.recoverEmail = '';
        $('#login_recover_modal').modal('hide');
    }
    clear() {
        this.loginEmail = '';
        this.loginPassword = '';
        this.signupAddress = '';
        this.signupCompany = '';
        this.signupEmail = '';
        this.signupName = '';
        this.signupPassword = '';
        this.signupPhone = '';
        this.signupType = 0;
    }
    clearError(errorSection) {
        switch (errorSection) {
            case 'recover':
                this.recoverError = '';
                break;
            case 'login':
                this.loginError = '';
                break;
            case 'signup':
                this.signupError = '';
                break;
        }
    }
    goBack() {
        Router.goTo('/');
    }
    noLoginAccess() {
        Router.goTo('/recomendation');
    }
    fileInput() {
        return this.$refs.fileInput;
    }
    onInputChanged() {
        if (this.fileInput().files && this.fileInput().files[0]) {
            var image = this.fileInput().files[0];
            if (image.type !== 'image/jpeg' && image.type !== 'image/png') {
                this.signupError = 'Las imágen debe ser de tipo JPG o PNG';
                return;
            }
            if (image.size > 3000000) {
                this.signupError = 'La imagen no puede ser mayor de 3 MB';
                return;
            }
            var reader = new FileReader();
            var me = this;
            reader.onload = function (ev) {
                console.log('aki');
                me.signupImage = ev.target.result.toString();
            };
            reader.readAsDataURL(this.fileInput().files[0]);
        }
    }
};
LoginPage = tslib_1.__decorate([
    Component
], LoginPage);
export default LoginPage;
