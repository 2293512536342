import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFoundPage from "@/pages/NotFound/NotFoundPage.vue";
import HomePage from "@/pages/Home/HomePage.vue";
import LoginPage from '@/pages/Login/LoginPage.vue';
import SettingsPage from '@/pages/Settings/SettingsPage.vue';
import ConfigurationPage from '@/pages/Configuration/ConfigurationPage.vue';
import RecomendationPage from '@/pages/Recomendation/RecomendationPage.vue';
Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage
    },
    {
        path: '/settings',
        name: 'SettingsPage',
        component: SettingsPage
    },
    {
        path: '/configuration',
        name: 'ConfigurationPage',
        component: ConfigurationPage
    },
    {
        path: '/recomendation',
        name: 'Recomendation',
        component: RecomendationPage
    },
    {
        path: '*',
        name: 'not-found',
        component: NotFoundPage
    }
];
const router = new VueRouter({
    routes,
    base: process.env.NODE_ENV === 'production' ? '/' : '/',
    mode: 'hash'
});
export default router;
