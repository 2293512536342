import * as tslib_1 from "tslib";
import Router from '@/classes/Router';
import { Component, Vue } from 'vue-property-decorator';
let NotFoundPage = class NotFoundPage extends Vue {
    created() {
        Router.goTo('/');
    }
};
NotFoundPage = tslib_1.__decorate([
    Component
], NotFoundPage);
export default NotFoundPage;
